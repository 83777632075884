<template>
    <el-drawer
        v-model="drawer"
        :direction="rtl"
        @closed="handleClose()"
        @opened="handleOpen()"
    >
        <el-scrollbar height="85vh">
            <h2 class="font-xx-large font-weight-bold ml-3">
                {{ user.fistName }} {{ user.lastName }}
            </h2>
            <el-form
                class="p-4"
                label-position="top"
                label-width="100px"
                :model="user"
                ref="user"
                :rules="rules"
            >
                <el-form-item label="Tipo de usuario" prop="usertype">
                    <el-select class="w-75" v-model="user.usertype">
                        <el-option
                            v-for="item in userTypes"
                            :key="item.id"
                            :value="item.id"
                            :label="item.name"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Nombre" prop="fistName">
                    <el-input class="w-75" v-model="user.fistName"></el-input>
                </el-form-item>
                <el-form-item label="Apellido" prop="lastName">
                    <el-input class="w-75" v-model="user.lastName"></el-input>
                </el-form-item>
                <el-form-item label="Género" prop="genreId">
                    <el-select class="w-75" v-model="user.genreId">
                        <el-option
                            v-for="genre in genres"
                            :key="genre.id"
                            :value="genre.id"
                            :label="genre.name"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Fecha de nacimiento" prop="dateOfBirth">
                    <el-date-picker
                        class="w-75"
                        v-model="user.dateOfBirth"
                        type="date"
                        size="large"
                        placeholder="Pick a day"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Número de teléfono" prop="cellphoneNumber">
                    <el-input
                        class="w-75"
                        v-model="user.cellphoneNumber"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Correo" prop="email">
                    <el-input class="w-75" v-model="user.email"></el-input>
                </el-form-item>
                <el-form-item label="Cédula de identidad" prop="identification">
                    <el-input
                        class="w-75"
                        v-model="user.identification"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="user.usertype == 'Delivery'"
                    label="Licencia de conducir"
                    :required="true"
                    error="Este campo es requerido"
                >
                    <el-input
                        class="w-75"
                        v-model="user.drivingLicense"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="user.usertype == 'Delivery'"
                    label="Fecha de expiración"
                    :required="true"
                    error="Este campo es requerido"
                >
                    <el-date-picker
                        class="w-75"
                        v-model="user.licenseExpiring"
                        type="date"
                        size="large"
                        placeholder="Pick a day"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    :required="!editMode"
                    error="Este campo es requerido"
                    label="Contraseña"
                    prop="password"
                >
                    <el-input
                        :show-password="true"
                        class="w-75"
                        v-model="user.password"
                    ></el-input>
                </el-form-item>
                <div class="w-75">
                    <el-button @click="$emit('drawerclosed')" round
                        >Cancelar</el-button
                    >
                    <el-button
                        class="pull-end bg-yellow"
                        style="float: right"
                        round
                        @click="manageUser()"
                        >Guardar</el-button
                    >
                </div>
                <el-divider></el-divider>
                <h3 class="font-large font-weight-bold font-italic">
                    Herramientas
                </h3>
                <el-button
                    type="default"
                    style="padding: 10px"
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleDeleteUser"
                ></el-button>
            </el-form>
        </el-scrollbar>
    </el-drawer>
    <!-- test -->
</template>

<script>
import { defineComponent } from "vue";
import lookoutService from "@/services/lookoutService";
import userService from "@/services/userService";
import {
    ElDrawer,
    ElButton,
    ElForm,
    ElScrollbar,
    ElSelect,
    ElDatePicker,
    ElOption,
    ElDivider,
    ElMessageBox,
    ElMessage,
} from "element-plus";
export default defineComponent({
    components: {
        ElDrawer,
        ElButton,
        ElForm,
        ElScrollbar,
        ElSelect,
        ElDatePicker,
        ElOption,
        ElDivider,
    },
    data() {
        return {
            drawer: false,
            user: {},
            genres: [],
            userTypes: [],
            rules: {
                fistName: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                lastName: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                genreId: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                dateOfBirth: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                cellphoneNumber: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                identification: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                usertype: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: null,
        },
    },
    watch: {
        isActive(value) {
            this.drawer = value;
        },
    },
    methods: {
        handleClose() {
            this.$emit("drawerclosed");
            this.$refs["user"].resetFields();
        },
        async handleDeleteUser() {
            ElMessageBox.confirm(
                `¿Deseas borrar permanentemente a ${this.user.fistName} ${this.user.lastName}?`,
                {
                    confirmButtonText: "Borrar",
                    cancelButtonText: "Cancelar",
                    type: "danger",
                    confirmButtonClass: "bg-danger",
                }
            )
                .then(async () => {
                    await this.deleteUser(this.user.id);
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "Acción cancelada",
                    });
                });
        },
        async deleteUser(id) {
            await userService
                .deleteUser(id)
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: "Usuario eliminado exitosamente",
                    });
                    this.$emit("refreshData");
                })
                .catch(() => {
                    ElMessage({
                        type: "danger",
                        message: "Ha ocurrido un error inesperado.",
                    });
                });
        },
        async getGenres() {
            const data = await lookoutService.getGenres();
            this.genres = data.data;
        },
        async getUserTypes() {
            const data = await lookoutService.getApplicationUserType();
            this.userTypes = data.data;
        },
        async manageUser() {
            let that = this;
            this.$refs["user"].validate(async (valid) => {
                if (valid) {
                    if (that.editMode) {
                        await that
                            .updateUser(this.user)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message: "Usuario actualizado exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    } else {
                        await that
                            .addUser(this.user)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message: "Usuario agregado exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        async addUser(user) {
            await userService.addUser(user);
            this.$emit("refreshData");
        },
        async updateUser(user) {
            await userService.updateUser(user);
            this.$emit("refreshData");
        },
        async handleOpen() {
            if (this.editMode) {
                var data = await userService.getUser({ id: this.userId });
                this.user = data.data;
                this.user.id = this.userId;
            } else {
                this.user = [];
            }
        },
    },
    async mounted() {
        await this.getGenres();
        await this.getUserTypes();
    },
});
</script>
<style lang="scss">
.el-form-item__label {
    font-weight: bold !important;
}
.el-icon {
    --font-size: 31px;
}
</style>
